body {
  margin: 0;
  padding: 0;
}
.rich-text-editor {
  height: 400px;
}
.rich-text-edit-content {
  height: 95%;
}

body {
  margin: 0;
  padding: 0;
}
.rich-text-editor {
  height: 400px;
}
.rich-text-edit-content {
  height: 95%;
}

.dwv-container {
  height: 70vh;
  width: 70vw;
}

.button-row {
  text-align: center;
}

/* Layers */
.layerContainer {
  position: relative;
  padding: 0;
  margin: auto;
  text-align: center;
}
.imageLayer {
  position: absolute;
  left: 0px;
}
.drawDiv {
  position: absolute;
  pointer-events: none;
}

